import { QueryStringParameters } from "@/interfaces/api";

class QuerystringHelper {
  generateSearchAndPagingParameters(parameters: QueryStringParameters): string {
    let queryString = "";

    if (parameters.search) {
      queryString += `Search=${parameters.search}&`;
    }

    queryString += `SortBy=${parameters.sortBy}&`;

    queryString += `SortOrder=${parameters.sortOrder}&`;

    queryString += `PageNumber=${parameters.pageNumber}&`;

    queryString += `PageSize=${parameters.pageSize}`;

    return queryString;
  }
}

export default new QuerystringHelper();
