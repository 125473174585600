import QuerystringHelper from "@/helpers/querystring-helper";
import AuthenticationService from "./authentication-service";
import {
  PagedList,
  Student,
  StudentParameters,
  OverviewStudent,
  PersonResponse,
  WF_NationalIdentityNumberChange,
} from "@/interfaces/api";
import BaseService from "@/services/base-service";

export interface UpdateResponse {
  response: WF_NationalIdentityNumberChange;
  success: boolean;
}

class StudentService extends BaseService {
  /**
   * Fetches the student with the specified id
   *
   * @param {number} id - The id of the student to fetch
   * @returns {Promise<PersonResponse>} A Promise with a person response
   */
  getStudentById(id: number): Promise<PersonResponse> {
    const endpointController = "Student";
    const enpointRoute = "id";
    const parameterName = "id";
    const parameterArgument = id;
    const getStudentById = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}?${parameterName}=${parameterArgument}`;
    return this.fetch<PersonResponse>(getStudentById);
  }

  /* Fetches students according to the specified filter/paging parameters
   *
   * @param {StudentParameters} studentParameters - The filter/paging parameters to be added to the resource querystring
   * @returns {Promise<PagedList<Student>>} A Promise with all the students that matched the parameters
   */
  getFiltered(
    studentParameters: StudentParameters
  ): Promise<PagedList<OverviewStudent>> {
    const endpointController = "Student";
    const enpointRoute = "search";
    const queryString = this.generateQuerystring(studentParameters);
    const getStudentById = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}${queryString}`;
    return this.fetch<PagedList<OverviewStudent>>(getStudentById);
  }

  /**
   * Returns a query string according to the specified filter parameters
   *
   * @param {StudentParameters} studentParameters - The filter/paging parameters to be added to the resource querystring
   * @returns {string} The generated query string
   */
  private generateQuerystring(studentParameters: StudentParameters): string {
    let queryString = "?";

    queryString += `CoordinatorId=${studentParameters.coordinatorId}&`;

    queryString +=
      QuerystringHelper.generateSearchAndPagingParameters(studentParameters);

    return queryString;
  }

  /**
   * Fetches a student response containing the student with the specified personal id and also a
   * boolean value representing whether or not the student already exists in the STP database
   *
   * @param {string} personalId - The personal id of the student to fetch
   * @returns {Promise<PersonResponse>} A Promise with a person response
   */
  getStudentResponseByPersonalId(personalId: string): Promise<PersonResponse> {
    const endpointController = "Student";
    const endpointRoute = "personalId";
    const parameterName = "personalId";
    const parameterArgument = personalId;
    const queryString = `${parameterName}=${parameterArgument}`;
    const getStudentResponseByPersonalId = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${queryString}`;
    return this.fetch<PersonResponse>(getStudentResponseByPersonalId);
  }

  /**
   * Fetches the student with the specified luEduPersonPrimaryId
   *
   * @param {string} luEduPersonPrimaryId - The luEduPersonPrimaryId of the student to fetch
   * @returns {Promise<Student>} A Promise with a student response
   */
  getStudentByLuEduPersonPrimaryId(
    luEduPersonPrimaryId: string
  ): Promise<Student> {
    const endpointController = "Student";
    const endpointRoute = "primaryId";
    const parameterName = "luEduPersonPrimaryId";
    const parameterArgument = luEduPersonPrimaryId;
    const queryString = `${parameterName}=${parameterArgument}`;
    const getStudentByLuEduPersonPrimaryId = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${queryString}`;
    return this.fetch<Student>(getStudentByLuEduPersonPrimaryId);
  }

  /**
   * Adds or updates a student
   *
   * @param {Student} student - The student to add och update
   * @returns {Promise<Student>} A promise with a boolean value indicating whether or not the operation was successful
   */
  addOrUpdateStudent(student: Student): Promise<Student> {
    const endpointController = "Student";
    const addOrUpdateStudent = `${this.baseApiUrl}api/${endpointController}`;
    return this.fetch<Student>(addOrUpdateStudent, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(student),
    });
  }

  /**
   * The `submitStudentData` function sends a POST request to submit student data.
   * @param {any} studentData - The data of the student to be submitted.
   * @returns A Promise that resolves to the response data.
   */
  async submitStudentData(
    studentData: WF_NationalIdentityNumberChange
  ): Promise<Student> {
    try {
      const endpointController = "Student/socialSecurityNumber";
      const submitStudentData = `${this.baseApiUrl}api/${endpointController}`;
      return this.fetch<Student>(submitStudentData, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(studentData),
      });
    } catch (error) {
      // Handle errors appropriately
      console.error("Error submitting student data: ", error);
      throw error;
    }
  }

  /**
   * Checks if the update of the national identity number for the student with the specified luEduPersonPrimaryId exists.
   *
   * @param {string} luEduPersonPrimaryId - The luEduPersonPrimaryId of the student to check
   * @returns {Promise<{ response: WF_NationalIdentityNumberChange | null; success: boolean }>} A Promise with the updated information
   */
  getIfUpdateIsInsertedByLuEduPersonPrimaryId(
    luEduPersonPrimaryId: string
  ): Promise<{
    response: WF_NationalIdentityNumberChange | null;
    success: boolean;
  }> {
    const endpointController = "Student";
    const endpointRoute = "updatedNationalIdentityNumber";
    const parameterName = "lucatid";
    const queryString = `${parameterName}=${encodeURIComponent(
      luEduPersonPrimaryId
    )}`;
    const getUpdateNationalIdentityNumber = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${queryString}`;
    return this.fetch<{
      response: WF_NationalIdentityNumberChange | null;
      success: boolean;
    }>(getUpdateNationalIdentityNumber);
  }

  /**
   * Exports student data to a excel file based on the provided date range.
   *
   * @param {string} startDate - The start date of the date range for the export.
   * @param {string} endDate - The end date of the date range for the export.
   */
  async exportStudentsToExcel(startDate: string, endDate: string) {
    const endpointController = "Student";
    const endpointRoute = "export";
    const queryString = `startDate=${startDate}&endDate=${endDate}`;
    const url = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${queryString}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${AuthenticationService.getJWTToken()}`,
      },
    });

    return response;
  }
}

export default new StudentService();
